import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../components/layout';
import Image from '../../components/image';
import GlossaryLink from '../../components/glossary-link';
import type { FrontMatter, ImageType } from '../../utils/types';
import Link from '../../components/link';
import { withFrontMatter } from '../../components';
import CanyonRatingCard from '../../components/canyon-rating-card';
import AllImagesLink from '../../components/all-images-link';

type Props = {
  frontMatter: FrontMatter,
  data: {
    _1: ImageType,
    _2: ImageType,
    _3: ImageType,
    _4: ImageType,
    _5: ImageType,
    _6: ImageType,
    _7: ImageType,
    _8: ImageType,
    _9: ImageType,
    _10: ImageType,
    _11: ImageType,
  },
};

class Blog_2019_03_16_North_Wash extends React.Component<Props> {
  render() {
    const { data, frontMatter } = this.props;

    return (
      <Layout frontMatter={frontMatter}>
        <p>
          Near the end of 2018/2019's long winter, canyoneering season has
          officially kicked off. Wow it was cold - we ended up abandoning our
          original plans and treated the weekend as if it were still winter.
        </p>
        <h2>Arscenic</h2>
        <CanyonRatingCard rating="3AII" title="Arscenic" />
        <p>
          We decided on descending a couple of the{' '}
          <Link to="http://ropewiki.com/Poison_Springs">Poison Springs</Link>{' '}
          canyons. Each of us got to descend at least one new canyon, so that
          was good.{' '}
          <Link to="http://ropewiki.com/Arscenic_Canyon">Arscenic</Link> was the
          first canyon.
        </p>
        <p>
          We arrived at the trailhead and spoke with a group who had done it the
          day prior. They said there was one unavoidable section of ankle-deep
          water and a couple other places where some tricky maneuvering would
          avoid the rest. The challenge to stay dry was on!
        </p>
        <p>
          Now is a good time to mention the first rule of canyoneering: Thou
          shalt not get injured trying to avoid water. Anyway, on to the rappel.
        </p>
        <Image caption="Starting the rappel" image={data._1} />
        <p>
          That first <GlossaryLink>rappel</GlossaryLink> can be broken up into a
          few smaller rappels or done as one long rappel. We chose to leave one
          long rope at the top that made it all the way down but still broke up
          the rappel into three parts. That way we could stop and make fun of
          each other through the entirety of the canyon{' '}
          <em>including the rappel</em>, rather than just when we were hiking.
        </p>
        <Image
          caption="Finishing up the second rappel section"
          image={data._2}
        />
        <Image
          caption="Me standing under a cresting wave. It crashed down on me right after taking the picture and I got all sandy but didn't take pictures of that."
          image={data._4}
        />
        <Image
          caption="Starting the final part of the rappel"
          image={data._3}
        />
        <p>
          After finishing the rappel we moved down the canyon and hit our first
          water obstacle. Randy easily{' '}
          <GlossaryLink id="bridging">bridged</GlossaryLink> over it and helped
          the rest of us across. We were very appreciative of his efforts and I
          dubbed him "The Hero of Arscenic". The title didn't catch on.
        </p>
        <Image
          caption="Stemming over what might have been the mandatory water"
          image={data._5}
        />
        <p>
          After that we were treated to the scenic portion of the canyon. The
          lighting was just spectacular - bright white walls up top that
          gradually changed color to a deep orange and then maroon the farther
          down they go.
        </p>
        <Image image={data._6} />
        <Image image={data._7} />
        <Image caption="Enjoying a dark corkscrew section" image={data._8} />
        <Image caption="More stemming over water" image={data._9} />
        <p>
          As always, the slot had to end. We had one more stop on our way back
          to the cars, though.
        </p>
        <Image caption="Arscenic Arch" image={data._10} />
        <Image
          caption="Arscenic canyon with Henry Mountains"
          image={data._11}
        />
        <p>
          With all the time spent avoiding water and fooling around, we probably
          didn't set any speed records. But we did tie the record for "most fun
          ever had in Arscenic" that day.
        </p>
        <h2>Constrychnine</h2>
        <CanyonRatingCard rating="3AII" title="Constrychnine" />
        <p>
          Lunch, bit of chatting, then{' '}
          <Link to="http://ropewiki.com/Constrychnine_Canyon">
            Constrychnine
          </Link>
          . What about pictures? None were post-worthy but you can see them all,
          plus a couple from the{' '}
          <Link to="http://ropewiki.com/index.php?search=shillelagh&title=Special%3ASearch&go=Go">
            Shillelaghs
          </Link>{' '}
          the next day.
        </p>
        <AllImagesLink id={frontMatter.id} />
      </Layout>
    );
  }
}

export default withFrontMatter('2019-03-16-north-wash')(
  Blog_2019_03_16_North_Wash
);

export const query = graphql`
  query {
    _1: file(
      relativePath: { eq: "images/blog/2019-03-16-north-wash/_DSF8858.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _2: file(
      relativePath: { eq: "images/blog/2019-03-16-north-wash/_DSF8869.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _3: file(
      relativePath: { eq: "images/blog/2019-03-16-north-wash/_DSF8878.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _4: file(
      relativePath: { eq: "images/blog/2019-03-16-north-wash/_DSF8881.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _5: file(
      relativePath: { eq: "images/blog/2019-03-16-north-wash/_DSF8894.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _6: file(
      relativePath: { eq: "images/blog/2019-03-16-north-wash/_DSF8899.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _7: file(
      relativePath: { eq: "images/blog/2019-03-16-north-wash/_DSF8903.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _8: file(
      relativePath: { eq: "images/blog/2019-03-16-north-wash/_DSF8910.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _9: file(
      relativePath: { eq: "images/blog/2019-03-16-north-wash/_DSF8919.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _10: file(
      relativePath: { eq: "images/blog/2019-03-16-north-wash/_DSF8928.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    _11: file(
      relativePath: {
        eq: "images/blog/2019-03-16-north-wash/_DSF8934-Pano.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
